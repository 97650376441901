import React from 'react'
import { useSelector } from 'react-redux'
import classNames from "classnames";
import './JackpotList.scss'

export const JackpotList = ({ type }) => {
  const jackpots = useSelector(state => state.profile.info.jackpots)
  const preloader = useSelector(state => state.handling.preloader)
  const game = useSelector(state => state.game)

  const renderElements = jackpots => (
    <>
    { game.code ? jackpots.map((jackpot, index) =>
      <div className={jackpot.activate ? `jackpotGame  active jackpot__name_${jackpot.name}` : `jackpotGame jackpot__name_${jackpot.name}`}  key={index} >
        <div className={classNames("jackpotContentGame")}>
          <img className={jackpot.activate ? `borderJackpotGameActivate imageGame` : `borderJackpotGame imageGame`} src="/images/selected_game.png" alt={`jackpot ${jackpot.name}`} />

          <div className={classNames("textJackpotGame")}>
            <span className={classNames("textGame", `${jackpot.name}`)}>{`${jackpot.name}`}</span>
            <span className={classNames("coinTextGame", "coin3")}>{jackpot.jackpot}</span>
          </div>
        </div>
      </div> 
    ) : jackpots.map((jackpot, index) =>
    <div className={jackpot.activate ? `jackpot  active jackpot__name_${jackpot.name}` : `jackpot jackpot__name_${jackpot.name}`}  key={index} >
      <div className={classNames("jackpotContent")}>
        <img className={jackpot.activate ? `borderJackpotActivate image` : `borderJackpot image`} src="/images/selected.svg" alt={`jackpot ${jackpot.name}`} />

        <div className={classNames("textJackpot")}>
          <span className={classNames("text", `${jackpot.name}`)}>{`${jackpot.name}`}</span>
          <span className={classNames("coinText", "coin3")}>{jackpot.jackpot}</span>
        </div>
      </div>
    </div> 
  ) }
    </>
  )


    return ( <>
      {!game.code ? 
      <div className="containerJackpotsList" data-visible={ !preloader }>  
      <div className="jackpotsWrapper">
        <div className="containerImageJackpots">
          <img className="circleImage" src='/images/Circle.png' alt='Circle jackpots' />
        </div>
        <div className="containerImageLianes">
          <img className="lianesImage" src='/images/Liannes.png' alt='Lianes' />
        </div>
        <div className="containerLogoImage">
          <img className="logoImage" src='/images/Jackpots.png'  alt='Logo' />
        </div>
        <div className='containerImageBird'>
          <img className='imageBird' src='/images/bird.png' alt='Bird' />
        </div>
        <div className="containerJacpots" data-type={type}>
          <div className='jackpots'>
            { (jackpots && jackpots.length > 0) ? renderElements(jackpots) : <></>  }
          </div>
        </div>
      </div>
    </div> 
    : 
    <div className="containerJackpotsListGame" data-visible={ !preloader }>  
      <div className="jackpotsWrapperGame">
        {/* <div className="containerImageJackpots">
          <img className="circleImage" src='/images/Circle.png' alt='Circle jackpots' />
        </div> */}
        {/* <div className="containerImageLianes">
          <img className="lianesImage" src='/images/Liannes.png' alt='Lianes' />
        </div> */}
      
        {/* <div className='containerImageBird'>
          <img className='imageBird' src='/images/bird.png' alt='Bird' />
        </div> */}
        <div className="containerJacpotsGame" data-type={type}>
          <div className='jackpotsGame'>
            { (jackpots && jackpots.length > 0) ? renderElements(jackpots) : <></>  }
          </div>
        </div>
      </div>
    </div> 
    
    } </>
    )
}
