import React, { useState } from 'react'
import './ProgressInfoTooltip.scss'

export const ProgressInfoTooltip = ({ value, renderText }) => {

    const [state, setState] = useState({ flag: false })

    function openCloseTooltip() {
        if (state.flag ) {
            setState({ ...state, flag: false })  // закрыть подсказку

        } else  {  
            setState({ ...state, flag: true })  // открыть подсказку
        }
    }

    return (
        <div className='box'>
            <div className='title-bonus'>
                <span className='text-title-bonus'>{value.title}</span>
                <button onClick={openCloseTooltip} className='btn-info' data-active={state.flag} >
                    <img className='btn-tooltip' src="/images/Subtract.png" data-active={state.flag}  alt='Subtract' />
                </button>
                <div className='popupInfo' data-active={state.flag}  >
                    <div className='textContent'>
                        {renderText(value.info)}
                    </div>
                </div>
            </div>
            <div className='progressLine'>
                <progress max={value.values.max} value={value.values.value} ></progress>
                <div className='valueBonuses'>
                    <span className='min'>{value.values.value}</span><span className='max'>{value.values.max}</span>
                </div>
            </div>
        </div>
    )
}