import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GamesHistory } from '@devvs/shared/dist/components'
import { ButtonLogout, ButtonClose, ButtonSound } from '@devvs/shared/dist/elements'  //  ButtonLanguages, ButtonSound,
import { setPopup } from '@devvs/redux/dist/store/actions'
import { Balance } from './balance'
import { ChangePassword } from './password-change'
import { QrCode } from './qr-code'
import { TransactionHistory } from '@devvs/shared/dist/components/Payments/TransactionHistory'
import { ModalWindow } from 'components/ModalWindow';
import './Profile.scss'

export const Profile = ({ visible }) => {

    const dispatch = useDispatch()
    const profileSections = useSelector(state => state.settings.profileSections)
    const words = useSelector(state => state.words)
    const [activeTab, setActiveTab] = useState('balance')

    const changeTab = type => setActiveTab(type)


    const renderTabs = tab => {
        switch (tab) {
            case 'balance':
                return <Balance />
            case 'change_password':
                return <ChangePassword />
            case 'transaction_history':
                return <TransactionHistory />  // уточнить должен ли открываться календарь при клике на поле
            case 'games_history':
                return <GamesHistory />
            case 'qr_code':
                return <QrCode />
            default:
                return <Balance />
        }
    }

    const renderMenu = (sections, active) => (
        sections.map(section => {
            let title = words.server[section]
            return <div className="linkMenu" key={section} data-active={active === section} onClick={() => changeTab(section)}>
                <div className='ico'>
                    {
                    (active === section) ?
                    <img className='imageIcon' src={'images/profile/' + section + '_active.svg'} data-active={active === section} alt={title} />
                    :
                    <img className='imageIcon' src={'images/profile/' + section + '.svg'} data-active={active === section} alt={title} />
                    }

                </div>
                <span>{title}</span>
            </div>
        })
    )

    return (

        <ModalWindow visible={visible}>
            <div className="profile">
                <div className="profile__wrapper">
                    <div className="menuProfile">
                        <div className="headerProfile">
                            <div className="title">{words.server.my_account}</div>
                        </div>
                        <div className='containerMenu'>
                            {/* <div className='mobile'>
                                <div className='logo'>
                                    <img src='images/logo.png' alt='Logo' />
                                </div>
                                <AccountButton />
                            </div> */}
                            <div className="itemMenu">
                                {renderMenu(profileSections, activeTab)}
                            </div>

                        </div>

                    </div>

                    <div className="contentTab">
                        <div className="controls">
                        <ButtonSound />
                            <div className='containerButtonLogout'>
                                <ButtonLogout />
                            </div>
                            <ButtonClose action={() => dispatch(setPopup({ active: false, name: '' }))} />
                        </div>
                        {
                            visible && <div className="tab">
                                {renderTabs(activeTab)}
                            </div>
                        }
                    </div>

                </div>
            </div>
        </ModalWindow>
    )
}

