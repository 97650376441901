import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom"
import "./index.scss";

import  StoreProvider  from "@devvs/redux"
import { Enter } from "components"
import { Helpers } from "@devvs/shared/dist/helpers"

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      
        <Helpers>
          <Enter/>
        </Helpers>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

