import React, { useEffect, useState, useRef }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSearch, setActiveGameList, setActiveInput } from '@devvs/redux/dist/store/actions'
import { useDebounce, useOutsideClick } from '@devvs/shared/dist/hooks'
import './Search.scss'


export const Search = () => {
    const dispatch = useDispatch()
    const settings = useSelector(state => state.settings)
    const ref = useRef()

    const words = useSelector(state => state.words)
    const size = useSelector(state => state.handling.size)
    const activeGameList = useSelector(state => state.handling.activeGameList)
    const handling = useSelector(state => state.handling)


    const [lastProvider, setLastProvider] = useState(activeGameList)
    const [value, setValue] = useState('')
    const [opened, setOpened] = useState((size.mobile && size.landscape) ? true : false)
    const debouncedSearchTerm = useDebounce(value, 500)

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm !== '') {
            dispatch( setSearch(debouncedSearchTerm))
            dispatch( setActiveGameList('search') )
        } else {
            dispatch( setActiveGameList(lastProvider) )
        }
    }, [debouncedSearchTerm, lastProvider, dispatch])  // [debouncedSearchTerm]

    useEffect(() => {
        if (activeGameList && activeGameList !== 'search') {
            setLastProvider(activeGameList)
            setValue('')
        }
    }, [activeGameList])

    useOutsideClick( ref, () => {
        if (!value && !(size.mobile && size.landscape) && !(handling.activeInput === 'search-input' && settings.virtualKeyboard && !handling.size.mobile)) {
            setOpened(false)
        }
    }, opened)

    const changeHandler = newValue =>  {
        if (newValue === '') {
            setValue('')
        } else {
            setValue( newValue )
            if (settings.virtualKeyboard && !handling.size.mobile) {
                window.VirtualKeyboard.setInput(newValue)
            }
        }
       
    }

    const clearTerm = () => {
        setValue('')
        if (settings.virtualKeyboard && !handling.size.mobile) {
            window.VirtualKeyboard.setInput('')
        }
        setOpened(false)
        ref.current.value = ''
        let event = new Event('input', { bubbles: true});
	    ref.current.dispatchEvent(event);
    }

    const blur = () => {  // When clicking outside the search field. Otherwise, the "search" icon does not appear in the field
        if(!value) {
            clearTerm()
        }
    }

    return (
        <div className="search">
            <input 
            type="text" 
            ref={ ref }
            id={'search-input'}
            className="input search-input"
            data-open={ opened }
            onClick={ () => setOpened(true) }
            onBlur= { blur }
            placeholder={ words.server.search } 
            value={ value } 
            
            onFocus={ (e) => {
                dispatch( setActiveInput('search-input') ) 
            }}

            onInput={ event => changeHandler(event.target.value) }  />
             <div className="clear" onClick={ () => clearTerm() } data-visible={ value ? true : false }/>
        </div>
    )
}