import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopup } from '@devvs/redux/dist/store/actions'

import './ProvidersButton.scss'

export const ProvidersButton = () => {
    const dispatch = useDispatch()
	const words = useSelector(state => state.words)
	const authorized = useSelector(state => state.profile.authorized)

    const handleAccountButton = () => {
		if (authorized) {
			dispatch(setPopup({ active: true, name: 'modal' }))
		} else {
			dispatch(setPopup({ active: true, name: 'auth' }))
		}
	}
    return(
        <button className="ProvidersButton" onClick={handleAccountButton}>{words.server.providers}</button>
    )
}