import React from 'react'
import { useSelector } from 'react-redux'
import { Download, PromoForm } from '@devvs/shared/dist/components'
import { ButtonLanguages } from '@devvs/shared/dist/elements'
import { ProgressInfoTooltip } from 'components/ProgressInfoTooltip'
import CompoundTimer from 'react-compound-timer'
import classNames from 'classnames';
import './balance.scss'

let obj = {}

export const Balance = () => {

	const words = useSelector(state => state.words)
	const profileInfo = useSelector(state => state.profile.info)
	const bonuses = useSelector(state => state.profile.info.bonuses)

	function getValues(arr) {
		try {
			for (const [key, value] of arr) {  // достает значения для progress и окна подсказки


				if (value === null || value === "undefined") {
					continue;
				}

				if (typeof value === "string") {

					Object.defineProperty(obj, key, {
						value: value,
						writable: true,
						enumerable: true,
						configurable: true
					});
				}

				if (typeof value === 'object') {

					let name = Object.keys(value)
					let newArr = Object.values(value)

					if (name[0] === "progress") { 
						let val = '';

						for (let i = 0; i < newArr.length; i++) {

							for (let j = 0; j < newArr[i].length; j++) {

								if (newArr[i][j].tag === name[0]) {

									val = newArr[i][j].attributes;

									Object.defineProperty(obj, "values", {
										value: Object.assign({}, val),
										writable: true,
										enumerable: true,
										configurable: true
									});
								}

							}
						}

					} else if (name[0] === "info") {

						for (let i = 0; i < newArr.length; i++) {

							let line = newArr[i]

							Object.defineProperty(obj, "info", {
								value: line,
								writable: true,
								enumerable: true,
								configurable: true
							});

						}
					} else {
						getValues(newArr);
					}
				}
			}

			return obj
		} catch (e) {
			console.log(e)
		}
	}

	const renderBonusesText = lines => (

		lines.map((line, index) => {
			if (line.tag === 'timer') {
				return (
					<CompoundTimer
						initialTime={line.value * 1000}
						direction="backward"
						key={'timer-' + index}
						formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
					>
						{() => (
							<>
								<CompoundTimer.Days formatValue={(value) => `${value} days `} />
								<CompoundTimer.Hours />:
								<CompoundTimer.Minutes />:
								<CompoundTimer.Seconds />
							</>
						)}
					</CompoundTimer>
				)
			}

			if (line.tag === 'oneinput') {
				return <PromoForm attributes={line.attributes} key={'promo-' + index} />
			}

			if (line.tag === 'progress') {
				return <div className='progressLine'>
					<progress max={line.attributes.max} value={line.attributes.valuee}></progress>
					<div className='valueBonuses'>
						<span className='min'>{line.attributes.value}</span><span className='max'>{line.attributes.max}</span>
					</div>
				</div>
			}

			if (line.tag === 'br') {
				return <br key={'br-' + index} />
			}

			return React.createElement(line.tag, { ...line.attributes, key: index }, line.value)
		})
	)

	const renderBonuses = bonuses => {

		let arr = Object.values(bonuses)

		let items = arr.map(bonus => {
			let main = false, additional = false

			const entries = Object.entries(bonus);
			let name = Object.keys(bonus.main)[0]

			if( bonus.name === "cumulative" ) {
				let value = Object.assign({}, getValues(entries));  // из метода приходит один объект, нужно копировать данные для каждого отдельного компонента иначе данные одинаковые во всех компонентах progress

					return <div className={classNames('bonus-item', `${bonus.name}`)} key={bonus.name}>
						{bonus.name === 'happy_hours' && <img className='present-image' src='images/present.png' alt='Present' />}
						<div className={name + ' text'}>
							<ProgressInfoTooltip value={value} renderText={renderBonusesText} />
						</div>
					</div>
			} 
			
			if( bonus.name === "cumulative_wheel" ) {
				let value2 = Object.assign({}, getValues(entries));  // из метода приходит один объект, нужно копировать данные для каждого отдельного компонента иначе данные одинаковые во всех компонентах progress
					
					return <div className={classNames('bonus-item', `${bonus.name}`)} key={bonus.name}>
						{bonus.name === 'happy_hours' && <img className='present-image' src='images/present.png' alt='Present' />}

						<div className={name + ' text'}>
							<ProgressInfoTooltip value={value2} renderText={renderBonusesText} />
						</div>
					</div>
			}



			if (bonus.additional) {
				additional = Object.values(bonus.additional).map((line, index) => {
					return <div className='text' key={index}>{renderBonusesText(line)}</div>
				})
			}

			if (bonus.main) {
				main = Object.values(bonus.main).map((line, index) => {
					let name = Object.keys(bonus.main)[index]

					return <div key={index} className={name + ' text'}>
						{renderBonusesText(line)}
					</div>
				})
			}


			return <div className={classNames('bonus-item', `${bonus.name}`)} key={bonus.name}>
				{bonus.name === 'happy_hours' && <img className='present-image' src='images/present.png' alt='Present' />}
				{main && main}
				{additional &&
					<div className={bonus.name + 'Container'}>{additional} </div>
				}
			</div>
		})

		return items
	}

	return (
		<div className={classNames('balanceTab')}>
			<div className='containerContetnt'>
				<div className='containerBalance'>
					<h2 className="titleTab">{words.server.balance}</h2>
					{profileInfo.name && <div className="fio">
						<div className="title">{words.server.fio}</div>
						<div className="value">{profileInfo.name}</div>
					</div>}

					<div className="login">
						<div className="title">{words.server.lo_gin}</div>
						<div className="value">{profileInfo.login}</div>
						{/* <div className="edit"></div> */}
					</div>

					<div className="balance">
						<div className="title">{words.server.balance}</div>
						<div className="value">{profileInfo.cash} {profileInfo.currency}</div>
						{/* <div className="edit"></div> */}
					</div>

					{bonuses.wager &&
						<div className="balance">
							<div className="title">{profileInfo.words.chargeable_balance}</div>
							<div className="value">{profileInfo.wager.chargeable} {profileInfo.currency}</div>
						</div>
					}
				</div>


				<div className="information">
					<div className="bonuses-info">
						{bonuses && renderBonuses(bonuses)}
					</div>
				</div>


				<div className='containerButtonLanguages'>
					<span>Language:</span>
					<ButtonLanguages direction={'bottom'} />
				</div>

				<div className='containerButtons'>
					<div className='contanerButton'>
						<Download />
					</div>
				</div>
			</div>
		</div>
	)
}

