import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveGameList, setSearch } from '@devvs/redux/dist/store/actions'
import './TypesButtons.scss'

export const TypesButtons = ({ switchTypesButtons }) => {

	const dispatch = useDispatch()
	const types = useSelector(state => state.games.types)
	const words = useSelector(state => state.words)
	const activeProvider = useSelector(state => state.handling.activeGameList)

	const renderButtons = types => (
		types.map(type => {
			let classNames = 'types-buttons__button '

			if (type === activeProvider) {
				classNames += 'active'
			}
			return (
				<div key={type} className={classNames} onClick={() => {
					dispatch(setActiveGameList(type)); dispatch(setSearch(false));
					switchTypesButtons()
				}}>
					<span>{words.server[type]}</span>
				</div>
			)
		})
	)

	return (
		<div className='wrapper-types-buttons'>
			<div className="types-buttons">
				{types && renderButtons(types)}
			</div>
		</div>
	)
}