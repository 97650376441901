import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import './Rectangle.scss';

export const Rectangle = ({ children, className }) => (
    <div className={classNames('rectangle', className)}
    >{children}
    </div>
)

Rectangle.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['default', 'light_sm', 'light_big']),
    theme: PropTypes.oneOf(['royal', 'default'])
}

Rectangle.defaultProps = {
    className: '',
    type: 'default',
    theme: 'default'
}