import { TypesButtons } from 'components/TypesButtons'
import { Search } from 'components/Search'
import { ProvidersButton } from 'components/ProvidersButton'
import { useSelector } from 'react-redux'

import './MenuGames.scss'

export const MenuGames = ({ switchBtn }) => {

    const preloader = useSelector(state => state.handling.preloader)


    return (
        <div className="menu"  data-visible={ !preloader }>
            <div className='containerTypesButtons'>
                <TypesButtons switchTypesButtons={switchBtn}  />
            </div>
            <div className="searchPanel">
                <Search />
                <ProvidersButton />
            </div>
        </div>
    )
}