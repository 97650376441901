import React from 'react'
import { useSelector } from 'react-redux'
import { Input } from '@devvs/shared/dist/components'
import classNames from 'classnames'

import './password.scss'

export const ChangePassword = () => {

  const words = useSelector(state => state.words)

  const [formData, getFormData] = React.useState({
    confirmPassword: "",
    newPassword: "",
    oldPassword: "",
  });
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState('');

  const getFormVal = (type, val) => {
    getFormData({
      ...formData,
      [type]: val
    })
  }

  function changePassword(data) {

    if (data.confirmPassword === '' || data.newPassword === '' || data.oldPassword === '') {
      setMessage("not_all_data_entered");
      setMessageType("error");
      return false;
    }
    else if (
      data.confirmPassword !== data.newPassword
    ) {
      setMessage("passwords_not_match");
      setMessageType("error");
      return false;
    }
    let response = window.core.query({
      cmd: "casinoPasswordChange",
      password: data.oldPassword,
      passwordNew: data.newPassword,
    });
    if (response.status === "success") {
      setMessage("password_change_succesfull");
      setMessageType("success");
    } else {
      setMessage(response.error);
      setMessageType("error");
    }
    return false;
  }

  return (
    <form autocomplete="off" className="tab tab--password">
      <div className="row">
        <label className="title">
          {words.server.password}
        </label>
        <div className="value">
          <Input
            type={"password"}
            onChange={(e) => getFormVal('oldPassword', e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <label className="title">
          {words.server.new_password}
        </label>
        <div className="value">
          <Input
            type={"password"}
            onChange={(e) => getFormVal('newPassword', e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="title">{words.server.confirm_password}</div>
        <label className="value">
          <Input
            type={"password"}
            onChange={(e) => getFormVal('confirmPassword', e.target.value)}
          />
        </label>
      </div>
      <div className={classNames("edit", "change-password")}
        onClick={() => changePassword(formData)}
      >
        {words.server.change_password}
      </div>
      <div className={`message message_${messageType}`}>{words.server[message]}</div>
    </form>
  )
}