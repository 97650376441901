import React, { useState, useEffect } from 'react'
import { Preloader } from '@devvs/shared/dist/components'

import './qrcode.scss'

export const QrCode = () => {
    const [qrCode, setQrcode] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setQrcode(window.core.query({ qrcode: window.core.terminal.content.id }).qrcode)
        }, 100)  
    },[])

    return (
        <div className="qrcode">
            <div className="code">
                { qrCode && <img className='image' src={ qrCode } alt="QRCode" /> }
                <Preloader visible={ !qrCode ? true : false }/>
            </div>
        </div>
    )
}