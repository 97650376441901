import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Lobby, Popup } from 'components'
import { Auth } from '@devvs/shared/dist/components/Auth'
import { Preloader } from '@devvs/shared/dist/components'
import { A2hs, ChatButton } from '@devvs/shared/dist/helpers'
import { Wheels } from '@devvs/fortune-freespins-wheel/dist/components/Wheels'
import { Profile } from 'components'
import { ProvidersBar } from 'components'
import { Background } from 'components'

import './Enter.scss'

export const Enter = () => {

  const profile = useSelector(state => state.profile)
  const handling = useSelector(state => state.handling)
  const config = useSelector(state => state.config)
  const game = useSelector(state => state.game)

  useEffect(() => {
    const body = window.document.getElementsByTagName('body')
    if (handling.size.mobile) {
      handling.activePopup.active ? body[0].style.overflow = "hidden" : body[0].style.overflow = "visible"
    }
  }, [handling.activePopup, handling.size.mobile])


  useEffect(() => {
    const body = window.document.getElementsByTagName('body')
    if (handling.activePopup.name === 'modal' && !handling.activePopup.name === 'account') {
      body[0].style.overflow = "hidden"
    } else {
      body[0].style.overflow = "visible"
    }
  }, [handling.activePopup])

  profile.beforeAuthorize || profile.authorized ? document.body.style.backgroundColor = 'rgb(33, 68, 62)' : document.body.style.backgroundColor = 'rgb(12, 30, 28)'
  profile.beforeAuthorize? document.body.style.overflow = 'visible' : document.body.style.overflow = 'hidden'
  !game.code ? document.body.style.overflow = 'visible' : document.body.style.overflow = 'hidden'

  const renderAlerts = () => (
    <div className="alerts" data-authorized={profile.authorized}>
      {config.jivosite && <ChatButton onClick={() => window.jivo_api.open()} />}
      {(config.PWA && !localStorage.pwa) && <A2hs />}
    </div>
  )

  return (
    <div className="view">
      <Wheels />
      {profile.authorized && <ProvidersBar visible={(handling.activePopup.name === 'modal')} />}
      {handling.size.mobile && renderAlerts()}
      <Preloader visible={handling.preloader} />
      <Popup />
      {profile.authorized ? null : <Background />}
      {(profile.beforeAuthorize || profile.authorized) ? <Lobby /> : <Auth />}
      {profile.authorized && <Profile visible={(handling.activePopup.name === 'account')} />}

    </div>
  )
}
