import React, { useState, useEffect } from 'react'
import { setActiveGameList, setPopup, setGameFullscreen } from '@devvs/redux/dist/store/actions'
import { useSelector, useDispatch } from 'react-redux'
import './Categories.scss'

export const Categories = ({ switchTypesButtons }) => {

	const dispatch = useDispatch()
	const words = useSelector(state => state.words)
	const authorized = useSelector(state => state.profile.authorized)
	const menuSections = useSelector(state => state.settings.menuSections)
	const activeGameList = useSelector(state => state.handling.activeGameList)
	const games = useSelector(state => state.games.views)

	const [buttons, setButtons] = useState(null)

	useEffect(() => {
		if (menuSections.length > 0) {
			let filteredButtons = filterButtons(menuSections)
			setButtons(renderButtons(filteredButtons))
			console.log("filteredButtons: ", filteredButtons)
		}

	}, [menuSections, games, activeGameList]) // eslint-disable-line

	const getLabelFromWords = name => {

		switch (name) {
			case 'sport-betting':
				return words.server['sport']
			case 'live-dealers':
				return words.server['dealers']
			case 'fast_games':
				return words.server['tv_games']
			default:
				return words.server[name] ? words.server[name] : name.toLowerCase().replace('-', ' ').replace('games', '').replace('betting', '').replace('dealers', '')
		}
	}

	const handleClick = (name, value) => {
		if (name !== 'sport-betting') {
			window.core.closeGame()
		} else {
			dispatch(setGameFullscreen(false))
		}

		if (value && typeof value === 'number') {
			dispatch(setActiveGameList(name))
			authorized ? window.core.openGame(value, '', 1, 0) : dispatch(setPopup({ active: true, name: 'auth' }))
			window.core.openGame(value, '', 1, 0)
		}

		else if (name === 'jackpots') {
			// dispatch(setActiveGameList(name))
			dispatch(setPopup({ active: true, name: name }))

		} else {
			dispatch(setActiveGameList(name))
		}

		switchTypesButtons();
	}

	const filterButtons = items => (
		items.filter(item => {
			let notGameList = ['jackpots', 'bonuses', 'deposit', 'tournament']
			let key = Object.keys(item)[0]
			let value = Object.values(item)[0]
			let byEnable = value !== false // проверка включено ли в settings
			let byGameList = !notGameList.includes(key) ? games[key] && games[key].length > 0 : true
			return byEnable && byGameList
		})
	)

	const renderButtons = items => {
		return items.map(item => {
			let key = Object.keys(item)[0]

			let value = Object.values(item)[0]
			let name = ''

			if (key === 'fishing-games') {  // в играх приход 2 объекта fishing-games и fishing
				name = key.replace('-', ' ').replace('games', '').trim()
			} else { name = key }

			return <div key={name} data-active={(name === activeGameList)} className="item" onClick={() => {
				handleClick(name, value);

			}}
						data-type={name}>
				<div className="icon" style={{ backgroundImage: `url(./images/categories/${name}.png)` }}></div>
				<span className="label">{getLabelFromWords(name)}</span>
			</div>
		})
	}

	if (buttons) return <span className="categories">{buttons}</span>
	return <></>
}
