import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPreloader } from '@devvs/redux/dist/store/actions'
import { GameCard } from 'components'
import _ from "lodash"
import './Games.scss'

let type = '';
let step = 16;  // 20,   сбрасывается до старого значенич после запуска игры
let currentProvider = null;
let lastIndex = 0;

export const Games = () => {

    const dispatch = useDispatch()
    const words = useSelector(state => state.words)
    const views = useSelector(state => state.games.views)   // .views
    const activeProvider = useSelector(state => state.handling.activeGameList)
    const preloader = useSelector(state => state.handling.preloader)

    let local = Number(window.localStorage.getItem('currentStep'))  // 0
    const [activeView, setActiveView] = useState(true)  // false
    const [stepState, setStepState] = useState(local)

    // console.log("activeView: ", activeView)

    if (views[activeProvider] && views[activeProvider].length > 19 && lastIndex !== views[activeProvider].length) {    //  activeProvider === 'all' | activeProvider === 'freespins'
        type = true
    } else {
        type = false
    }

    // console.log(activeProvider)

    useEffect(() => {
        if (activeProvider && views && views.all.length > 0) {
            let view = views[activeProvider]

            currentProvider = window.localStorage.getItem('lastProvider')
            window.localStorage.setItem('lastProvider', activeProvider);  // перезаписываем провайдера

            if (activeProvider !== currentProvider) {
                // при переходе на другой провайдер сбрасывается значение stepState, отсчет начинается сначала.
                // Предыдущее значение добавленых на страницу игр по нажатию на кнопку "Больше" обнуляется.
                window.localStorage.setItem('currentStep', step);
                setStepState(0);
                lastIndex = 0;
            }

            setActiveView(
                view?.filter((game, index) => {
                    if (stepState === 0) {
                        window.localStorage.setItem('currentStep', step)
                        let state = Number(window.localStorage.getItem('currentStep'));
                        setStepState(state)
                    } else {
                        window.localStorage.setItem('currentStep', stepState)
                    }

                    if (index < stepState) {

                        if (lastIndex <= index) ++lastIndex;  //      window.localStorage.getItem('currentLastIndex')
                        return game
                    }
                    return game
                    // return game - в этом месте не возможно установить, исчезает возможность добавления кнопки для добавления игр на страницу
                })
            )
        }
    }, [views, activeProvider, stepState])

    const debouncedLazyLoad = _.debounce(() => {
        if (preloader === true) {
            dispatch(setPreloader(false))
        }
    }, 2000)


    function addGames() {
        let val = stepState + 16;
        setStepState(val)  // 20
    }

    const renderSlides = (games) => {

        if (games.length > 0) {
            return games.map(game => {

                let img = game.img.replace('game_img', 'game_img_4')
                return <GameCard
                    key={game.id}
                    id={game.id}
                    name={game.name}
                    label={game.label}
                    img={img}
                    demo={false}
                    fs={game.fs}
                    onLoad={debouncedLazyLoad}
                />
            })
        }
    }

    return (
        <>
            <div className={activeView?.length === 0 ? 'GamesEmpty' : 'Games'} data-visible={!preloader}>
                { activeView && renderSlides(activeView) ?  renderSlides(activeView) : <p className='noGames'>{words.server.no_games}</p>}
            </div>
            { type ?
                    <div className='containerBtnMore'>
                        <button className='btnMore' onClick={addGames}>{words.server.show_more}</button>
                    </div>
                    : null }
        </>
    )
}
