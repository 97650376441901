import { setPreloader } from '@devvs/redux/dist/store/actions'
import { GameFrame } from '@devvs/shared/dist/components'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import _ from "lodash"
import { Header } from 'components/Header'
import { JackpotList } from 'components/JackpotList'
import { Games } from 'components/Games'
import { JackpotListMobile } from 'components/JackpotListMobile'
import { MenuGames } from 'components/MenuGames'
import './Lobby.scss'


export const Lobby = () => {

  const gridRef = useRef();
  const menuRef = useRef();

  const dispatch = useDispatch()
  const game = useSelector(state => state.game)
  const activeProvider = useSelector(state => state.handling.activeGameList)
  const [stateJackpots, setStateJackpots] = useState(false)
  const debouncedLastProvider = _.debounce(provider => { window.localStorage.setItem('lastProvider', provider) }, 800);
  const [isActiveTopBtn, setIsActiveTopBtn] = useState(false);


  useEffect(() => {
    debouncedLastProvider(activeProvider)
    setTimeout(() => {
      dispatch(setPreloader(false))
      gridRef.current.scroll(
          {
            top: window.localStorage.getItem('lastScrollPosition'),
            behavior: "smooth",
          }
      );
    }, 3600);
  }, [dispatch])  // eslint-disable-line

  function switchTypesButtons() {
    let top = document.getElementById('top');
    let position = Math.round(top.getBoundingClientRect().y);
    let header = document.getElementById('header').clientHeight;

    while (position > (header + 30)) {
      --position;

      let scroll = Number(window.localStorage.getItem('lastScrollPosition'));
      let currentPosition = Math.round(scroll);

      window.localStorage.setItem('lastScrollPosition', ++currentPosition);
    }

  }

  function toTop() {
    window.localStorage.setItem('lastScrollPosition', 0);
    gridRef.current.scroll(
        {
          top: 0,
          behavior: "smooth",
        }
    );
  }

  function getScroll(e) {
    let windowHeight = document.documentElement.clientHeight;

    if (!game.code) {
      window.localStorage.setItem('lastScrollPosition', e.target.scrollTop);
    }

    if (e.target.scrollTop > windowHeight) {
      setIsActiveTopBtn(true)
    }else{
      setIsActiveTopBtn(false)
    }
  }

  return (
    <div className='lobby' data-active={game.code && true} ref={gridRef} onScroll={(e) => getScroll(e)
    }  >
      <div className='wrapper'>
        <Header switchBtn={switchTypesButtons} />
        <main>
          {game.code && <GameFrame gameCode={game.code} />}
          {!game.code && <div className="content">
            <JackpotList refMenu={menuRef} />
            <div className='anchor' id="top"></div>
            <MenuGames switchBtn={switchTypesButtons} />
            <div className="containerGames">
              <Games />
              <div className='innerImages'>
                <div>
                  <img className='leftImagePalm2' src='/images/palmBackground.png' alt='Palm background' />
                  <img className='leftImagePalm' src='/images/palmLeftBottom.png' alt='Palm background' />
                </div>

                <div className='groupImagesRightBottom'>
                  <img className='imagePineapple' src='/images/pineapple.png' alt='Pineapple background' />
                  <img className='rightImagePalm' src='/images/palmRightBottom.png' alt='Palm background' />
                  <img className='rightImagePalm2' src='/images/palmBackground.png' alt='Palm background' />
                </div>
              </div>
            </div>
          </div>}

          <div className='backgroundJackpotsListButton' data-active={stateJackpots}></div>
          <div className='backgroundJackpotsListButton'></div>

          {!game.code && <JackpotListMobile active={stateJackpots} setState={setStateJackpots} />}
        </main>
      </div>

      {!game.code && <div className='background'>
        <div className='imageBg'>
          <img src='/images/Blurred.png' alt='Blurred background' />
        </div>

        <div className='imageBg2'>
          <div className='topImage'>
            <img className='topImagePalm' src='/images/palmTop.png' alt='Palm background' />
          </div>


          <div className='groupImagesRightTop'>
            <img src='/images/rightElem.png' alt='Background element' />
          </div>

          <div className='LeftTopPalme'>
            <img className='leftTopImagePalm' src='/images/palmLeftBottom.png' alt='Palm background' />
          </div>
        </div>

        <div className='imageBg3'>
          <div className='groupTopImages'>
            <div>
              <img src='/images/coconut.png' alt='Coconut background' />
            </div>
            <div>
              <img src='/images/Banana2.png' alt='Banana background' />
            </div>
            <div>
              <img src='/images/pineapple.png' alt='Pineapple background' />
            </div>
            <div className='rotateImage'>
              <img src='/images/fruit.png' alt='Fruiit background' />
            </div>
            <div className='coinTop'>
              <img src='/images/coin3.png' alt='Coin background' />
            </div>
          </div>
          <div className='groupMiddleImages'>
            <div className='coinMiddle'>
              <img src='/images/coin2.png' alt='Coin background' />
            </div>
            <div className='BananaMiddle'>
              <img src='/images/Banana.png' alt='Banana background' />
            </div>
          </div>
          <div className='groupBottomImages'>
            <div>
              <img src='/images/fruit.png' alt='Fruiit background' />
            </div>
            <div>
              <img src='/images/coconut2.png' alt='Coconut background' />
            </div>
          </div>
        </div>
      </div>}
      <div  style={ stateJackpots ? {display: 'none'} : {display: 'flex'} } className='containerBtnToTop' onClick={toTop} >
        <button className={`btnToTop ${isActiveTopBtn ? 'active' : ''}`} >
          <img src='images/top.png' alt='Top' />
        </button>
      </div>
    </div>
  )
}
