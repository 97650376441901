import React from 'react'
import { useDispatch } from 'react-redux'
import { JackpotList } from '@devvs/shared/dist/components'
import { ButtonClose } from '@devvs/shared/dist/elements'
import { setPopup } from '@devvs/redux/dist/store/actions'

import './popup-jackpots.scss'
import { formatUrlVersion } from '@devvs/shared/dist/features'


const PopupJackpots = () => {

    const dispatch = useDispatch()

    return (
        <div className="popup popup_jackpots">
            <img className='logo' src={formatUrlVersion('images/logo.png')} alt='Logo'/>
            <JackpotList type={'mobile-list'}/>
            <ButtonClose action={ () => dispatch( setPopup({ active: false, name: '' }) )}/>
        </div>
    )
}

export default PopupJackpots;
