import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopup } from '@devvs/redux/dist/store/actions'
import user from './assets/User.svg'
import userActive from './assets/UserActive.svg'
import './AccountButton.scss'

export const AccountButton = () => {

	const dispatch = useDispatch()
	const words = useSelector(state => state.words)
	const profile = useSelector(state => state.profile.info)
	const casino = useSelector(state => state.config.casino)
	const authorized = useSelector(state => state.profile.authorized)
	const handling = useSelector(state => state.handling)

	const handleAccountButton = () => {
		if (authorized) {
			dispatch(setPopup({ active: true, name: 'account' }))
		} else {
			dispatch(setPopup({ active: true, name: 'auth' }))
		}
	}

	return (
		<div className="AccountButton" onClick={handleAccountButton}>

			{authorized &&
				<span className="cash">
					{profile.cash} <span className='currency'>{profile.currency}</span>
				</span>
			}

			<div className='text'>
				{authorized ?
					<>
						<div className='default' data-active={handling.activePopup.name === 'account' ? true : false} >
							<img className='user_ico' src={user} alt="Coins" />
							<span className='user'> {profile.login}</span>
						</div>

						{handling.activePopup.name === 'account' ?
							<div className='account'>
								<img className='user_ico' src={userActive} alt="Coins" />
								<span className='user'> {profile.login}</span>
							</div>
							: null}

					</>
					:
					<>
						<span className='login'>{words.server.login}</span>
						{casino && <span className='signin'>{' / ' + words.server.registration}</span>}
					</>
				}
			</div>
		</div>
	)
}