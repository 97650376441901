import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import './popup-sms.scss'
import { setPopup } from '@devvs/redux/dist/store/actions'
import { Input } from '@devvs/shared/dist/components'
import { ButtonClose } from '@devvs/shared/dist/elements'

const PopupSms = () => {

    const dispatch = useDispatch()
    const [formData, getFormData] = React.useState({})

    useEffect(() => {

    },[])

    const getFormVal = (type, val) => {
          getFormData({
              ...formData,
              [type]: val
          })
    }

    return (
        <div className="popup popup_sms">
            <ButtonClose action={ () => dispatch( setPopup({ active: false, name: '' }) )}/>
            <Input inputType={"mail"} valType={"email"} placeholder="E-mail" getFormVal={getFormVal} />
        </div>
    )
}

export default PopupSms;
