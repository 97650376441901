import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonFavorite } from '@devvs/shared/dist/elements'
import { setPopup, setGameFullscreen } from '@devvs/redux/dist/store/actions'
import './GameCard.scss'

export const GameCard = ({ id, name, label, img, fs, onLoad }) => {

	const dispatch = useDispatch()
	const authorized = useSelector(state => state.profile.authorized)

	const handlePlay = (id, label) => {
		if (authorized) {
			id === '1537' && dispatch(setGameFullscreen(false))
			window.core.openGame(id, label, 1, 0)
			window.localStorage.setItem('currentGameId', id)
		} else {
			dispatch(setPopup({ active: true, name: 'auth' }))
		}
	}

	const renderImage = (img, name) => {
		return <img loading="lazy" onLoad={onLoad} onClick={() => handlePlay(id, label)} className="image" src={img} alt={name} />

	}

	return (
		<div id={id} className="GameCard">
			<div className="inner">

				{renderImage(img, name)}

				<ButtonFavorite id={id} />

				{fs && <div className="freespin" />}

			</div>

		</div>
	)
}