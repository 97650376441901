import { useSelector } from 'react-redux'
import classNames from "classnames";
import './JackpotListMobile.scss'

export const JackpotListMobile = ({ type, theme, active, setState }) => {

    const jackpots = useSelector(state => state.profile.info.jackpots)

    const renderElements = jackpots => (
        jackpots.map((jackpot, index) =>
            <div className={jackpot.activate ? `jackpot  active jackpot__name_${jackpot.name}` : `jackpot jackpot__name_${jackpot.name}`} key={index} >
                <div className={classNames("jackpotContent")}>
                    <div className={classNames("textJackpot")}>
                        <span className={classNames("text", `${jackpot.name}`)}>{`${jackpot.name}`}</span>
                        <span className={classNames("coinText", "coin3")}>{jackpot.jackpot}</span>
                    </div>
                </div>
            </div>
        )
    )
    return (
        <div className='containerJackpotsListMobile' data-active={active}>
            <div className='contentJackpotsListMobile' data-active={active} onClick={() => active ? setState(false) : setState(true)}>
                <div className='openJackpotsListMobile'></div>
                <div className='contentJackpotsMobile' data-active={active}>
                    <div className={classNames('jackpotMobile')}>
                        {
                            jackpots ? renderElements(jackpots) : (window.core.jackpots && renderElements(window.core.jackpots))
                        }
                    </div>
                </div>
                <img className='btnImage' src='/images/Jackpots.png' onClick={() => active ? setState(false) : setState(true)}  alt='Logo' />
            </div>
        </div>
    )
}