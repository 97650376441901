import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import CountUp from 'react-countup';

import { Rectangle } from './Rectangle/Rectangle';

import './List.scss';

export const Item = ({ jackpot, isResult }) => (
  <div
    className={classNames('spin-jackpot', `spin-jackpot_${jackpot.name}`, {
      'active': jackpot.activate,
      'jackpot-result': isResult
    })}>
    <Rectangle className='spin-jackpot__name' >
      <div className={classNames('spin-jackpot__name')} name={jackpot.name}>{jackpot.name}
      </div>
    </Rectangle>

    <Rectangle className='spin-jackpot__digit'>
      <div className={classNames('spin-jackpot__digit_text')}>
        <CountUp decimals={2} preserveValue={true} duration={3} end={jackpot.jackpot} />
      </div>
    </Rectangle>
  </div>
)

Item.propTypes = {
  jackpot: PropTypes.any.isRequired,
  isResult: PropTypes.bool,
  theme: PropTypes.oneOf(['royal', 'default'])
}

Item.defaultProps = {
  isResult: false,
  theme: 'default'
}