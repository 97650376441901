import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveGameList } from '@devvs/redux/dist/store/actions'
import { ButtonLanguages, ButtonFullscreen, ButtonClose } from '@devvs/shared/dist/elements'
import { ListPanel } from '../JackpotSpin/ListPanel/ListPanel'
import { JackpotList } from 'components/JackpotList'
import { Categories } from '../Categories'
import { AccountButton } from './AccountButton'
import classNames from 'classnames';
import './Header.scss'

export const Header = ({ switchBtn }) => {

    const dispatch = useDispatch()
    const preloader = useSelector(state => state.handling.preloader)
    const size = useSelector(state => state.handling.size)
    const handling = useSelector(state => state.handling)
    const gameFullscreen = useSelector(state => state.game.fullscreen)
    const game = useSelector(state => state.game)
    const gameCode = useSelector(state => state.game.code)
    const words = useSelector(state => state.words)
    const [stateMobileGameJackpots, setStateMobileGameJackpots] = useState(false)

    console.log("handling: ", handling.activePopup.name)

    let buttonLanguages = document.querySelector(".ButtonLanguages")

    if (game.code) {
        buttonLanguages.classList.add("game")
    }


    function menuMobileGameJackpots() {
        if (stateMobileGameJackpots) setStateMobileGameJackpots(false)
        else setStateMobileGameJackpots(true)
    }

    const renderControls = () => {
        if (gameCode) {
            if (size.mobile && !gameFullscreen) {
                return (
                    <div className="controls">
                        <div className='jackpotsHeader'>
                            <span className='jackpotsHeaderTitle' onClick={menuMobileGameJackpots} >{words.server.jackpots}</span>
                            <div className='contentJackpotsHeader' data-active={stateMobileGameJackpots}>
                                <ListPanel />
                            </div>
                        </div>
                        <AccountButton />
                        <ButtonFullscreen />
                        <ButtonClose action={() => window.core.closeGame()} />
                    </div>
                )
            } else {
                return (
                    <div className="controls" data-games={game.code ? true : false}>
                        <div className='jackpotsHeader' data-games={game.code ? true : false}>
                            <span className='jackpotsHeaderTitle' onClick={menuMobileGameJackpots}  >{words.server.jackpots}</span>
                            <div className='contentJackpotsHeader' data-active={stateMobileGameJackpots}>
                                <JackpotList />
                            </div>
                        </div>
                        <ButtonLanguages direction={'bottom'} />
                        <ButtonFullscreen />
                        <ButtonClose action={() => { window.core.closeGame(); }} />
                    </div>
                )
            }
        } else {
            return (
                <div className="controls">
                    <AccountButton />
                    <ButtonLanguages direction={'bottom'} />
                </div>
            )
        }
    }

    return (
        <header className={classNames('header')} data-visible={!preloader} data-game={game.code ? true : false} data-active={(handling.activePopup.name === 'account')}>
            <div id="header" className='container' data-active={game.code ? true : false}>
                <div className='logo' onClick={() => dispatch(setActiveGameList('all'))}>
                    <img className='defaultImage' src='images/logo.png' alt='Logo' />
                    <div className='containerActiveImage'><img className='activeImage' src='images/logo.png' alt='Logo' /></div>
                </div>

                {!game.code ?
                    <div className='containerCategoriesList'  data-active={(handling.activePopup.name === 'account')}>
                        <Categories switchTypesButtons={switchBtn} />
                    </div>
                    : null}

                {renderControls()}
            </div>
        </header>
    )
}
