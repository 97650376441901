import React from 'react'
import './popup-bonuses.scss'
//import { Bonuses } from 'components'
import { ButtonClose } from '@devvs/shared/dist/elements'

const PopupBonuses = () => {

    return (
        <div className="popup popup_bonuses">
            <ButtonClose action={ () => window.core.closeGame() }/>
            {/*<Bonuses/>*/}
        </div>
    )
}

export default PopupBonuses;
