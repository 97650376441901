import './Background.scss'

export const Background = ({ elem }) => {
  return (
    <div className='backgroundBlured'>
      <div className='imageBg'>
        <img src='/images/Blurred.png' alt='Blurred background' />
      </div>

      <div className='imageBg2'>
        <div className='topImage'>
          <img className='topImagePalm' src='/images/palmTop.png' alt='Palm background' />
        </div>

        <div className='groupImagesRightTop'>
          <img src='/images/rightElem.png' alt='Background element' />
        </div>

        <div className='innerImages'>
          <div >
            <img className='leftImagePalm' src='/images/palmLeftBottom.png' alt='Palm background'  />
          </div>


          <div className='groupImagesRightBottom'>
            <img className='imagePineapple' src='/images/pineapple.png' alt='Pineapple background' />
            <img className='rightImagePalm' src='/images/palmRightBottom.png' alt='Palm background'  />
          </div>


        </div>
      </div>

      <div className='imageBg3'>
        <div className='groupTopImages'>
          <div>
            <img src='/images/coconut.png' alt='Coconut background' />
          </div>
          <div>
            <img src='/images/Banana2.png' alt='Banana background' />
          </div>
          <div>
            <img src='/images/pineapple.png' alt='Pineapple background' />
          </div>
          <div className='rotateImage'>
            <img src='/images/fruit.png' alt='Fruiit background' />
          </div>
          <div>
            <img src='/images/coin.png' alt='Coin background' />
          </div>
        </div>
        <div className='groupMiddleImages'>
          <div>
            <img src='/images/coin2.png' alt='Coin background' />
          </div>
          <div>
            <img src='/images/Banana.png' alt='Banana background' />
          </div>
        </div>
        <div className='groupBottomImages'>
          <div>
            <img src='/images/fruit.png' alt='Fruiit background' />
          </div>
          <div>
            <img src='/images/coconut2.png' alt='Coconut background' />
          </div>
        </div>
      </div>
      <div className='backgroundPalms'>
        <div className='left'>
          <img src='/images/palmsLef.png' alt='Palm background'  />
        </div>
        <div className='rigth'>
          <img src='/images/palmsRight.png' alt='Palm background'  />
        </div>
      </div>
      {elem}
    </div>
  )
}