import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPopup, setActiveGameList, setSearch } from '@devvs/redux/dist/store/actions'
import { ModalWindow } from 'components/ModalWindow'
import { ButtonClose } from '@devvs/shared/dist/elements'
import './ProvidersBar.scss'

export const ProvidersBar = ({ visible }) => {

  const dispatch = useDispatch()
  const providersViews = useSelector(state => state.games.views)
  const providers = useSelector(state => state.games.providers)
  const words = useSelector(state => state.words)

  const renderSlides = providers => (
    providers.map(item => (
      <div className='provider' key={item.label} onClick={() => { dispatch(setActiveGameList(item.label)); dispatch(setSearch(false)); dispatch(setPopup({ active: false, name: '' })) }}>
        <div className='image'>
          <img className='imageProvider' src={`/resources/sitepics/lobby/providers_default/${item.label}.png`} alt='' />
        </div>
        <span className='label' >{item.label.replace('_', ' ').replace('betting', 'games')}</span>
        <span className='amount'>{`${item.amount} games`}</span>
      </div>
    ))
  )

  function getAmountGames(providersViews, providers) {
    const arrayProviders = []

    const array = providers.filter(item => item.label !== "all").map(item => item.label)
    //  выводим объекты из объекта providers.views

    Object.entries(providersViews).forEach(([k, v]) => {

      if (array.includes(k)) {
        arrayProviders.push({ "label": k, "amount": v.length })
      }

    })
    return renderSlides(arrayProviders)

  }

  return (
    <ModalWindow visible={visible}>
      <div className='contentProvidersBar'>
        {/* <div className='backgroundHeader'>
          <div className='mobile'>
            <div className='logo'>
              <img src='images/logo.png' alt='Logo' />
            </div>
            <AccountButton />
          </div>
        </div> */}

        <div className='containerButtonClose'>
          <ButtonClose action={() => dispatch(setPopup({ active: false, name: '' }))} />
        </div>
        <div className='cardProviders'>
          <h2>{words.server.providers}</h2>
          <div className="providers">
            {getAmountGames(providersViews, providers)}
          </div>
        </div>
      </div>
    </ModalWindow>

  )
}
