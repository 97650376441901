import React from 'react'
import './ModalWindow.scss'

export const ModalWindow = (props) => {

    if(props.visible) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "visible";
    }

    return (
        <div className="modalWindow" data-visible={props.visible}>
                {props.children}
        </div>
    )
}